import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//MLS
export const getMLS = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/mls`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//MLS
export const getMLSById = (id, key) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/mls/${key}/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const contactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/contact-us`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Agents
//Get All Agents List
export const getAgents = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/agent/`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get Single Agent Details By Id
export const getAgentDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/agent/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Events
//Get All Event List
export const getEvents = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/event/getAll`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get Single Event Details By Id
export const getEventDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/event/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// RSVP
//Post Request For RSVP
export const sendRSVPDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/rsvp`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Update Single Event Details By Id
export const updateRSVPDetailsById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/event/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Delete Single Event Details By Id
export const deleteRSVPDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/event/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Blogs
//Get All Blog List
export const getBlogs = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/blog/`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get Single Blog Details By Id
export const getBlogDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/blog/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Home Evaluation
export const homeEvaluation = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/home-evaluation`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// New Home Evaluation Post Request
export const homeEvaluationNew = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`user/new-home-evaluation`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Update New Home Evaluation Details By Id
export const updateHomeEvaluationNewById = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/new-home-evaluation/` + id, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//GET INSTAGRAM POSTS
export const getInstagramPosts = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/instagramPosts`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};