/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

import actions from "../redux/actions/userAction";

import { useDispatch } from "react-redux";

const BlogSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [category, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  useEffect(() => {
    if (category && categoryName) {
      dispatch(
        actions.setBlogDetails({
          categoryName: categoryName,
          categoryId: category,
        })
      );
      navigate("/blogs");
    }
  }, [category, categoryName]);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="col-xl-3 col-lg-3 col-sm-12"
    >
      <div className="sidebar">
        {/* CATEGORIES */}
        <div className="blog-categories">
          <div className="box-header">
            <h5>Blog Categories</h5>
          </div>
          <div className="box-body">
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741d5caa40f2e07b6eb7e");
                setCategoryName("Become a Real Estate Agent");
              }}
            >
              Become a Real Estate Agent
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741e2caa40f2e07b6eb81");
                setCategoryName("Buyer's Agent");
              }}
            >
              Buyer's Agent
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741e7caa40f2e07b6eb84");
                setCategoryName("Buying a Home");
              }}
            >
              Buying a Home
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741eccaa40f2e07b6eb87");
                setCategoryName("Buying and Selling Homes");
              }}
            >
              Buying and Selling Homes
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741f0caa40f2e07b6eb8a");
                setCategoryName("Home Inspection");
              }}
            >
              Home Inspection
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741f5caa40f2e07b6eb8d");
                setCategoryName("Listing Agent");
              }}
            >
              Listing Agent
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("644741fbcaa40f2e07b6eb90");
                setCategoryName("Real Estate Agent");
              }}
            >
              Real Estate Agent
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("64474200caa40f2e07b6eb93");
                setCategoryName("Sell Your Home");
              }}
            >
              Sell Your Home
            </Link>
            <Link
              className="category-link"
              onClick={() => {
                setCategory("64474204caa40f2e07b6eb96");
                setCategoryName("Selling Real Estate");
              }}
            >
              Selling Real Estate
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default BlogSidebar;
