/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

/* ICON IMPORTS */
import { AiFillCaretRight } from "react-icons/ai";

/* APIS */
import { getBlogs } from "../service/api";

/* CONFIG */
import { IMAGE_URL } from "../config";

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <div className="blog-box">
        <img src={IMAGE_URL + props.blogCover} alt={props.blogTitle} />
        <div className="overlay">
          <div className="details">
            <h5>{props.blogTitle}</h5>
            <Link className="route-link">
              Continue reading <AiFillCaretRight size="12" color="#FFF" />
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Blogs = () => {
  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [blogsData, setBlogsData] = useState([]);

  /* GET BLOGS LISTINGS */
  const getBlogsList = () => {
    setLoading(true);
    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  /* GET BLOGS ONLOAD */
  useEffect(() => {
    getBlogsList();
  }, []);

  useEffect(() => {
    getBlogsList();
  }, [page]);

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="blogs"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-sm-12">
            <div className="heading">
              <h2>THE MOST RECENT LOCAL REAL ESTATE NEWS</h2>
            </div>
          </div>
        </div>
        <div className="row mt-2 gy-4">
          {blogsData.slice(0, 4).map((blog) => {
            return (
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12" key={blog.id}>
                <BlogBox
                  blogCover={blog?.image}
                  blogTitle={blog?.name}
                  blogDetails={blog}
                />
              </div>
            );
          })}
        </div>
      </div>
    </motion.section>
  );
};

export default Blogs;
