import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { motion } from "framer-motion";

/* APIS */
import { contactUs } from "../../service/api";

/* STYLES */
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { AGENT_ID } from "../../config";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().required("Phone Number is required"),
  message: yup.string().required("Message is required"),
});

const ContactMe = () => {
  const [loading, setLoading] = useState(false);

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      type: "CONTACTUS",
      agentId: AGENT_ID,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm();
            toast.success(res?.message);
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  /* META TITLE */
  const metaTitle = "Contact Me | Benny Chavez";
  const metaDescription =
    "Reach out to me, your dedicated real estate agent, for personalized assistance in buying, selling, or renting properties.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  return (
    <section className="contact-us">
      <ToastContainer />

      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-sm-12">
                <h1>LET'S WORK TOGETHER</h1>
                <h2>Fill in the details to book an appointment.</h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* LOCATION MAP */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="details"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <form className="contact-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-heading">
                      <h2>Drop us a line</h2>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.name && formik.touched.name
                            ? "validation-border"
                            : ""
                        }`}
                        placeholder="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          formik.errors.email && formik.touched.email
                            ? "validation-border"
                            : ""
                        }`}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Phone <span>*</span>
                      </label>
                      <PhoneInput
                        placeholder="Phone Number"
                        type="text"
                        defaultCountry="US"
                        name="phone"
                        value={formik.values.phone}
                        onChange={(phone) =>
                          formik.setFieldValue("phone", phone)
                        }
                        className={`phone-number-select ${
                          formik.errors.phone && formik.touched.phone
                            ? "validation-border"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea
                        rows="4"
                        className={`textarea-control ${
                          formik.errors.message && formik.touched.message
                            ? "validation-border"
                            : ""
                        }`}
                        name="comments"
                        value={formik.values.message}
                        onChange={(message) =>
                          formik.setFieldValue("message", message.target.value)
                        }
                        placeholder="Short message"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <Button type="submit" className="submit-btn">
                      {loading ? <Spinner size="sm" /> : "Submit Details"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-6 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.678419408901!2d-118.10038262372736!3d33.872177627215805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2d6edc655555%3A0x196ed5fae37531a9!2s11060%20Artesia%20Blvd%20%23%20G%2C%20Cerritos%2C%20CA%2090703%2C%20USA!5e0!3m2!1sen!2sin!4v1683788140484!5m2!1sen!2sin"
                title="Amberwood Real Estate Office"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default ContactMe;
