/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import ListingListView from "./components/listing-list-view";

/* API IMPORTS */
import { getMLS } from "../../service/api";

/* ICON IMPORTS */
import GridIcon from "@iconscout/react-unicons/icons/uil-apps";
import ListIcon from "@iconscout/react-unicons/icons/uil-list-ul";
import FilterIcon from "@iconscout/react-unicons/icons/uil-sliders-v-alt";
import SearchIcon from "@iconscout/react-unicons/icons/uil-search";
import CloseIcon from "@iconscout/react-unicons/icons/uil-times";
import PreviousIcon from "@iconscout/react-unicons/icons/uil-previous";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* PAGINATE IMPORT */
import ReactPaginate from "react-paginate";

import PropertyLoadingList from "./components/property-loading-list";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from "../../assets/amberwood-favicon.png";

/* GOOGLE MAP IMPORTS */
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DistanceMatrixService,
  OverlayView,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

/* IMPORT COMBOBOX STYLES IMPORT */
import "@reach/combobox/styles.css";

/* IMPORT MAP STYLES */
import mapStyles from "./components/map-styles";

/* GOOGLE MAP API CONSTS */
const libraries = ["places"];

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "greedy",
  DistanceMatrixService,
};
/* GOOGLE MAP CENTER */
let center = {
  lat: 33.87597833032534,
  lng: -118.0597111810068,
};

/* GOOGLE MAP ZOOM LEVEL */
let zoom = 10;

/* FILTER OPTIONS LIST DATA START */
const sortingOptions = [
  { value: "ListPrice", label: "List price" },
  { value: "YearBuilt", label: "Year built" },
  { value: "LotSizeArea", label: "Lot size area" },
  { value: "DaysOnMarket", label: "Days on Market" },
];

const minBedOptions = [
  { value: "No Min", label: "No Min" },
  { value: "Studio", label: "Studio" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

const maxBedOptions = [
  { value: "No Max", label: "No Max" },
  { value: "Studio", label: "Studio" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

const minBathOptions = [
  { value: "No Min", label: "No Min" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

const maxBathOptions = [
  { value: "No Max", label: "No Max" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

const homeSizeMinOptions = [
  { value: "No Min", label: "No Min" },
  { value: "500", label: "500" },
  { value: "750", label: "750" },
  { value: "1000", label: "1,000" },
  { value: "1250", label: "1,250" },
  { value: "1500", label: "1,500" },
  { value: "1750", label: "1,750" },
  { value: "2000", label: "2,000" },
  { value: "2250", label: "2,250" },
  { value: "2500", label: "2,500" },
  { value: "2750", label: "2,750" },
  { value: "3000", label: "3,000" },
  { value: "3500", label: "3,500" },
  { value: "4000", label: "4,000" },
  { value: "5000", label: "5,000" },
  { value: "6000", label: "6,000" },
  { value: "7000", label: "7,000" },
  { value: "8000", label: "8,000" },
  { value: "8000+", label: "More than 8000" },
];

const homeSizeMaxOptions = [
  { value: "No Max", label: "No Max" },
  { value: "500", label: "500" },
  { value: "750", label: "750" },
  { value: "1000", label: "1,000" },
  { value: "1250", label: "1,250" },
  { value: "1500", label: "1,500" },
  { value: "1750", label: "1,750" },
  { value: "2000", label: "2,000" },
  { value: "2250", label: "2,250" },
  { value: "2500", label: "2,500" },
  { value: "2750", label: "2,750" },
  { value: "3000", label: "3,000" },
  { value: "3500", label: "3,500" },
  { value: "4000", label: "4,000" },
  { value: "5000", label: "5,000" },
  { value: "6000", label: "6,000" },
  { value: "7000", label: "7,000" },
  { value: "8000", label: "8,000" },
  { value: "8000+", label: "More than 8000" },
];

const lotSizeMinOptions = [
  { value: "No Min", label: "No Min" },
  { value: "0.25", label: ".25 acres" },
  { value: "0.5", label: ".5 acres" },
  { value: "1", label: "1 acres" },
  { value: "2", label: "2 acres" },
  { value: "3", label: "3 acres" },
  { value: "4", label: "4 acres" },
  { value: "5", label: "5 acres" },
  { value: "10", label: "10 acres" },
  { value: "40", label: "40 acres" },
  { value: "100", label: "100 acres" },
];

const lotSizeMaxOptions = [
  { value: "No Max", label: "No Max" },
  { value: "0.25", label: ".25 acres" },
  { value: "0.5", label: ".5 acres" },
  { value: "1", label: "1 acres" },
  { value: "2", label: "2 acres" },
  { value: "3", label: "3 acres" },
  { value: "4", label: "4 acres" },
  { value: "5", label: "5 acres" },
  { value: "10", label: "10 acres" },
  { value: "40", label: "40 acres" },
  { value: "100", label: "100 acres" },
];

const yearBuiltMinOptions = [
  { value: "No Min", label: "No Min" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2010", label: "2010" },
  { value: "2005", label: "2005" },
  { value: "2000", label: "2000" },
  { value: "1995", label: "1995" },
  { value: "1990", label: "1990" },
  { value: "1980", label: "1980" },
  { value: "1970", label: "1970" },
  { value: "1960", label: "1960" },
  { value: "1950", label: "1950" },
  { value: "1940", label: "1940" },
  { value: "1920", label: "1920" },
];

const yearBuiltMaxOptions = [
  { value: "No Max", label: "No Max" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2010", label: "2010" },
  { value: "2005", label: "2005" },
  { value: "2000", label: "2000" },
  { value: "1995", label: "1995" },
  { value: "1990", label: "1990" },
  { value: "1980", label: "1980" },
  { value: "1970", label: "1970" },
  { value: "1960", label: "1960" },
  { value: "1950", label: "1950" },
  { value: "1940", label: "1940" },
  { value: "1920", label: "1920" },
];

const timeOnAmberwood = [
  { value: "No Max", label: "No Max" },
  { value: "3 days", label: "Less than 3 days" },
  { value: "7 days", label: "Less than 7 days" },
  { value: "14 days", label: "Less than 14 days" },
  { value: "30 days", label: "Less than 30 days" },
  { value: "more 7 days", label: "More than 7 days" },
  { value: "more 14 days", label: "More than 14 days" },
  { value: "more 30 days", label: "More than 30 days" },
  { value: "more 45 days", label: "More than 45 days" },
  { value: "more 60 days", label: "More than 60 days" },
  { value: "more 90 days", label: "More than 90 days" },
  { value: "more 180 days", label: "More than 180 days" },
];

const parkingSpacesMin = [
  { value: "No Min", label: "No Min" },
  { value: "1", label: "1+" },
  { value: "2", label: "2+" },
  { value: "3", label: "3+" },
  { value: "4", label: "4+" },
  { value: "5", label: "5+" },
];

const parkingSpacesMax = [
  { value: "No Max", label: "No Max" },
  { value: "1", label: "1+" },
  { value: "2", label: "2+" },
  { value: "3", label: "3+" },
  { value: "4", label: "4+" },
  { value: "5", label: "5+" },
];

const storiesMin = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
];

const storeisMax = [
  { value: "No Max", label: "No Max" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
];

const minPriceOptions = [
  { value: null, label: "No Min" },
  { value: "100000", label: "100K" },
  { value: "125000", label: "125K" },
  { value: "150000", label: "150K" },
  { value: "175000", label: "175K" },
  { value: "200000", label: "200K" },
  { value: "225000", label: "225K" },
  { value: "250000", label: "250K" },
  { value: "275000", label: "275K" },
  { value: "300000", label: "300K" },
  { value: "325000", label: "325K" },
  { value: "350000", label: "350K" },
  { value: "375000", label: "375K" },
  { value: "400000", label: "400K" },
  { value: "425000", label: "425K" },
  { value: "450000", label: "450K" },
  { value: "475000", label: "475K" },
  { value: "500000", label: "500K" },
  { value: "550000", label: "550K" },
  { value: "600000", label: "600K" },
  { value: "650000", label: "650K" },
  { value: "700000", label: "700K" },
  { value: "750000", label: "750K" },
  { value: "800000", label: "800K" },
  { value: "850000", label: "850K" },
  { value: "900000", label: "900K" },
  { value: "1000000", label: "1M" },
  { value: "2000000", label: "2M" },
  { value: "3000000", label: "3M" },
  { value: "4000000", label: "4M" },
  { value: "5000000", label: "5M" },
  { value: "6000000", label: "6M" },
  { value: "7000000", label: "7M" },
  { value: "8000000", label: "8M" },
  { value: "9000000", label: "9M" },
  { value: "10000000", label: "10M" },
  { value: "12000000", label: "12M" },
  { value: "15000000", label: "15M" },
  { value: "20000000", label: "20M" },
  { value: "25000000", label: "25M" },
];

const maxPriceOptions = [
  { value: null, label: "No Max" },
  { value: "100000", label: "100K" },
  { value: "125000", label: "125K" },
  { value: "150000", label: "150K" },
  { value: "175000", label: "175K" },
  { value: "200000", label: "200K" },
  { value: "225000", label: "225K" },
  { value: "250000", label: "250K" },
  { value: "275000", label: "275K" },
  { value: "300000", label: "300K" },
  { value: "325000", label: "325K" },
  { value: "350000", label: "350K" },
  { value: "375000", label: "375K" },
  { value: "400000", label: "400K" },
  { value: "425000", label: "425K" },
  { value: "450000", label: "450K" },
  { value: "475000", label: "475K" },
  { value: "500000", label: "500K" },
  { value: "550000", label: "550K" },
  { value: "600000", label: "600K" },
  { value: "650000", label: "650K" },
  { value: "700000", label: "700K" },
  { value: "750000", label: "750K" },
  { value: "800000", label: "800K" },
  { value: "850000", label: "850K" },
  { value: "900000", label: "900K" },
  { value: "1000000", label: "1M" },
  { value: "2000000", label: "2M" },
  { value: "3000000", label: "3M" },
  { value: "4000000", label: "4M" },
  { value: "5000000", label: "5M" },
  { value: "6000000", label: "6M" },
  { value: "7000000", label: "7M" },
  { value: "8000000", label: "8M" },
  { value: "9000000", label: "9M" },
  { value: "10000000", label: "10M" },
  { value: "12000000", label: "12M" },
  { value: "15000000", label: "15M" },
  { value: "20000000", label: "20M" },
  { value: "25000000", label: "25M" },
];

const FeaturedListings = () => {
  /* GOOGLE MAP REPLATED STATES START */
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw",
    libraries,
  });
  /* STATES */
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);

  const mapRef = React.useRef();

  /* ON GOOGLE MAP LOAD */
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(
    ({ lat, lng, city, state, country, unparsedAddress }) => {
      handleFilterAddress();
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(12);
      setPage(1);
      setParams({
        ...params,
        city: city,
        state: state,
        unparsedAddress: unparsedAddress,
        Latitude: null,
        Longitude: null,
        isFilter: true,
        useLatLng: false,
        page: 1,
      });
    },
    []
  );

  /* MAP PIN */
  const MapMarker = (props) => {
    return (
      <div
        className="p-relative"
        onClick={props.onclickFn}
        style={{ cursor: "pointer" }}
      >
        <div className="map-pin">
          <img
            src={props.imageSelected}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = AmberwoodPlaceholder;
            }}
            alt="Property"
          />
        </div>
      </div>
    );
  };

  /* INITIAL FUNCTIONS */
  const location = useLocation();

  /* VIEW TYPE USESTATE */
  const [viewType, setViewType] = useState("grid");
  const [viewFilters, setViewFilters] = useState(false);

  /* VARIABLES USESTATES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [mlsData, setMLSData] = useState([]);
  const [mlsTotal, setMLSTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [callOutFunction, setCallOutFunction] = useState(false);

  const [homePageQueryData, setHomePageQueryData] = useState(false);

  /* QUERY PARAMETER DATA */
  const queryParameters = new URLSearchParams(location.search);

  /* DEFAULT FILTERS */
  let defaultFilters = {
    page: 1,
    isFilter: true,
    propertyStatus: ["Active"],
    minBedroom: null,
    maxBedroom: null,
    minPrice: 1,
    maxPrice: null,
    minBath: null,
    maxBath: null,
    location: null,
    propertySubType: [],
    propertyType: [],
    appliances: [],
    minYearBuilt: null,
    maxYearBuilt: null,
    minDaysOnMarket: null,
    maxDaysOnMarket: null,
    minLotSize: null,
    maxLotSize: null,
    minLotSizeAcres: null,
    maxLotSizeAcres: null,
    minLatitude: null,
    maxLatitude: null,
    minLongitude: null,
    maxLongitude: null,
    sortBy: null,
    agentMlsId: null,
    minStories: null,
    maxStories: null,
    maxParkingSpace: null,
    minParkingSpace: null,
    featured: null,
    useLatLng: false,
    state: null,
    city: null,
    unparsedAddress: null,
    Latitude: null,
    Longitude: null,
  };

  /* PARAMS USE-STATE */
  const [params, setParams] = useState(defaultFilters);

  /* GOOGLE MAP CENTER IF CAME FROM HOME PAGE */
  useEffect(() => {
    if (queryParameters.get("city") && queryParameters.get("state")) {
      let query = {};
      let text = "";
      if (queryParameters.get("address")) {
        text += queryParameters.get("address");
        text += ", ";
      }
      if (queryParameters.get("city")) {
        query = { ...query, city: queryParameters.get("city") };
        text += queryParameters.get("city");
        text += ", ";
      }
      if (queryParameters.get("state")) {
        query = { ...query, state: queryParameters.get("state") };
        text += queryParameters.get("state");
        text += ", ";
      }
      if (queryParameters.get("country")) {
        text += queryParameters.get("country");
      }

      setHomePageQueryData(text);
      let oldParams = params;
      for (const key in query) {
        oldParams[key] = query[key];
      }
      let latUse = parseFloat(queryParameters?.get("lat"));
      let lngUse = parseFloat(queryParameters?.get("lng"));
      if (queryParameters.get("address")) {
        if (queryParameters.get("lat")) {
          query = { ...query, Latitude: queryParameters.get("lat") };
        }
        if (queryParameters.get("lng")) {
          query = { ...query, Longitude: queryParameters.get("lng") };
        }
      }

      if (latUse && lngUse) {
        center.lat = latUse;
        center.lng = lngUse;
      }

      setParams(oldParams);
      setCallOutFunction(true);
    } else {
      setCallOutFunction(true);
    }
  }, [queryParameters.get("city")]);

  /* HANDLE REMOVE ADDRESS FROM HOMEPAGE */
  const handleFilterAddress = () => {
    /* DELETE ALL QUERY PARAMETERS */
    queryParameters.delete("lat");
    queryParameters.delete("lng");
    queryParameters.delete("city");
    queryParameters.delete("state");
    queryParameters.delete("country");
    queryParameters.delete("address");

    /* REWRITE URL */
    window.history.pushState(null, null, window.location.pathname);

    /* RECENTER TO DEFAULT LOCATION */
    mapRef.current.panTo({ lat: 33.87597833032534, lng: -118.0597111810068 });
    setParams({
      ...params,
      Latitude: null,
      city: "Cerritos",
      state: null,
      Longitude: null,
    });
    setHomePageQueryData(false);
    setCallOutFunction(true);
  };

  /* PAGINATION PAGE CLICK */
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setLoading(true);
    setMLSData([]);
    setPage(selectedPage);
    setParams({ ...params, page: selectedPage });
    setCallOutFunction(true);
  };

  /* GET MLS DATA */
  const getMLSData = (props) => {
    /* SET LOADING TRUE */
    setLoading(true);

    /* GET ALL QUERY PARAMETERS */
    let datas = params;
    let obj = datas;
    obj.page = page;
    // obj.sizePerPage = 20;
    if (props?.tag === "search") {
      obj.minLatitude = null;
      obj.maxLatitude = null;
      obj.minLongitude = null;
      obj.maxLongitude = null;
    }
    if (props?.sortBy) {
      obj.sortBy = props?.sortBy;
    }
    if (props?.isFilter) {
      obj.isFilter = props?.isFilter;
    }
    obj.featured = true;
    // if (obj?.propertySubTypes?.length > 0) {
    //     obj.propertySubType = obj?.propertySubType.toString();
    // }
    // if (obj?.propertyType?.length > 0) {
    //     obj.propertyType = obj?.propertyType.toString();
    // }
    // if (obj?.propertyStatus?.length > 0) {
    //     obj.propertyStatus = obj?.propertyStatus.toString();
    // }
    getMLS(datas)
      .then((res) => {
        if (res.status) {
          let list = [];
          let length = 0;
          res?.data?.map((obj) => {
            if (obj?.value?.length > 0) {
              list = list.concat(obj?.value);
              length += obj["@odata.count"];
            }
          });

          // SET ALL DATA TO MLS-DATA VARIABLE
          setMLSData(list);
          if (length > 0) {
            list?.forEach((element) => {
              // SET ALL LAT-LNG TO MARKERS VARIABLE
              setMarkers((current) => [
                ...current,
                {
                  lat: element?.Latitude,
                  lng: element?.Longitude,
                  data: element,
                  time: new Date(),
                },
              ]);
            });
          }
          // SET TOTAL-LENGTH TO MLS-LENGTH VARIABLE
          setMLSTotal(length);

          // CALCULATE TOTAL-PAGE-LENGTH
          setTotalPages(Math.ceil(length / 10));
          // dispatch(actions.setMLS(res?.data[1]?.value));
        }
      })
      .finally((res) => {
        /* SET LOADING FALSE */
        setLoading(false);
      });
  };

  /* USE-EFFECT TO HANDLE GET API */
  useEffect(() => {
    /* IF PARAMS CHANGES AND CALLOUT FUNCTION IS TRUE THEN HIT GET MLS DATA FUNCTION */
    if (callOutFunction) {
      getMLSData();
      setCallOutFunction(false);
    }
  }, [callOutFunction]);

  /* FILTERS APPLY FUNCTION */
  const handleApplyFiltera = () => {
    setPage(1);
    setMarkers([]);
    setParams({ ...params, isFilter: true });
    setCallOutFunction(true);
    setViewFilters(!viewFilters);
  };

  /* CLEAR FILTERS */
  const clearFilters = () => {
    setParams(defaultFilters);
    setCallOutFunction(true);
  };

  if (loadError) return "";
  if (!isLoaded) return "";

  const metaTitle = "Featured Listings | Benny Chavez";
  const metaDescription =
    "Explore our exclusive collection of featured listings, showcasing the finest properties in the market.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document.title = metaTitle;

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  return (
    <>
      <section className="active-listings-page-2">
        <div className="container">
          <div className="row">
            {/* PROPERTY LISTINGS */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-6 col-sm-12"
            >
              <div className="property-listings">
                {/* SECTION HEADER */}
                <div className="section-header">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-sm-12">
                      {/* SEARCH BOX */}
                      <div className="search-location-box">
                        <Search panTo={panTo} />
                      </div>
                    </div>
                    {/* VIEW CHANGER */}
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                      <div className="switcher-buttons">
                        <div className="d-flex align-items-center">
                          <p>
                            Showing{" "}
                            {mlsTotal && (
                              <>
                                <span>
                                  {mlsTotal
                                    ? mlsTotal
                                    : mlsData?.length
                                      ? mlsData?.length
                                      : 0}
                                </span>{" "}
                                homes{" "}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FILTERS */}
                <div className="price-filters mb-3 mt-3">
                  <div className="row justify-content-between">
                    {/* PRICE FILTERS */}
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                      <div className="form-group mb-0">
                        <div className="row">
                          {/* MIN PRICE DROPDOWN */}
                          <div className="col-xl-6 col-6">
                            <div className="p-relative">
                              <Select
                                options={minPriceOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Min Price"
                                aria-label="Min Price"
                                value={
                                  params?.minPrice
                                    ? {
                                      value:
                                        params?.minPrice == 1
                                          ? "No Min"
                                          : params?.minPrice,
                                      label:
                                        params?.minPrice == 1
                                          ? "No Min"
                                          : params?.minPrice,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  // if (e.value === "No Min") {
                                  //   setParams({
                                  //     ...params,
                                  //     minPrice: null,
                                  //   });
                                  //   return;
                                  // }
                                  setParams({
                                    ...params,
                                    minPrice: e.value,
                                  });
                                  setCallOutFunction(true);
                                }}
                              />
                              <p className="dollar-sign">$</p>
                            </div>
                          </div>
                          {/* MAX PRICE DROPDOWN */}
                          <div className="col-xl-6 col-6">
                            <div className="p-relative">
                              <Select
                                options={maxPriceOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Max Price"
                                aria-label="Max Price"
                                value={
                                  params?.maxPrice
                                    ? {
                                      value: params?.maxPrice,
                                      label: params?.maxPrice,
                                    }
                                    : { value: null, label: "No Max" }
                                }
                                onChange={(e) => {
                                  // if (e.value === "No Max") {
                                  //   setParams({
                                  //     ...params,
                                  //     maxPrice: null,
                                  //   });

                                  //   return;
                                  // }
                                  setParams({
                                    ...params,
                                    maxPrice: e.value,
                                  });
                                  setCallOutFunction(true);
                                }}
                              />
                              <p className="dollar-sign">$</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* SORT BY AND OTHER FILTERS */}
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="row">
                        {/* SORT BY OPTIONS */}
                        <div className="col-xl-7 col-6">
                          <Select
                            options={sortingOptions}
                            className="react-select sort-control"
                            classNamePrefix="custom-select"
                            placeholder="Sort by"
                            aria-label="Sort by"
                            value={
                              params?.sortBy && {
                                value: params?.sortBy,
                                label: params?.sortBy,
                              }
                            }
                            onChange={(e) => {
                              setParams({
                                ...params,
                                sortBy: e?.value ? e?.value : null,
                              });
                              getMLSData({
                                sortBy: e?.value ? e?.value : null,
                              });
                            }}
                          />
                        </div>
                        {/* OTHER FILTERS OPTIONS */}
                        <div className="col-xl-5 col-6">
                          <Button
                            className="filters-btn"
                            onClick={() => setViewFilters(!viewFilters)}
                          >
                            <FilterIcon color="#323232" size="16" />
                            <p>Filters</p>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* FILTERS APPLIED BOX */}
                {homePageQueryData ? (
                  <div className="filters-applied">
                    <p>Filters:</p>
                    <div className="applied-filters-list">
                      <div className="applied-filter">
                        <p>
                          Address:{" "}
                          <b style={{ fontWeight: "600" }}>
                            {homePageQueryData}
                          </b>
                        </p>
                        <Button
                          className="close-btn"
                          onClick={handleFilterAddress}
                        >
                          <CloseIcon size="14" color="#008080" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* FILTERS */}
                <motion.div
                  className={`filters-header ${viewFilters ? "d-block" : "d-none"
                    }`}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <form>
                    <div className="row">
                      {/* BEDS */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="beds" className="form-label">
                            Beds
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={minBedOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Min Bed"
                                aria-label="Min Bed"
                                value={
                                  params?.minBedroom
                                    ? {
                                      value: params?.minBedroom,
                                      label: params?.minBedroom,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minBedroom: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minBedroom: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={maxBedOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Max Bed"
                                aria-label="Max Bed"
                                value={
                                  params?.maxBedroom
                                    ? {
                                      value: params?.maxBedroom,
                                      label: params?.maxBedroom,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxBedroom: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxBedroom: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* BATHS */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="baths" className="form-label">
                            Baths
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={minBathOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Min Baths"
                                aria-label="Min Baths"
                                value={
                                  params?.minBath
                                    ? {
                                      value: params?.minBath,
                                      label: params?.minBath,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minBath: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minBath: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={maxBathOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Max Bath"
                                aria-label="Max Bath"
                                value={
                                  params?.maxBath
                                    ? {
                                      value: params?.maxBath,
                                      label: params?.maxBath,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxBath: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxBath: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5 className="sub-heading">PROPERTY FACTS</h5>

                      {/* HOME SIZE */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="home-size" className="form-label">
                            Home Size (sqft)
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={homeSizeMinOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Home size"
                                aria-label="Home size"
                                value={
                                  params?.minLotSize
                                    ? {
                                      value: params?.minLotSize,
                                      label: params?.minLotSize,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minLotSize: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minLotSize: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={homeSizeMaxOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Home size"
                                aria-label="Home size max"
                                value={
                                  params?.maxLotSize
                                    ? {
                                      value: params?.maxLotSize,
                                      label: params?.maxLotSize,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxLotSize: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxLotSize: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* LOT SIZE */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="lot-size" className="form-label">
                            Lot Size (acres)
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={lotSizeMinOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Lot size"
                                aria-label="Lot size"
                                value={
                                  params?.minLotSizeAcres
                                    ? {
                                      value: params?.minLotSizeAcres,
                                      label: params?.minLotSizeAcres,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minLotSizeAcres: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minLotSizeAcres: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={lotSizeMaxOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Lot size"
                                aria-label="Lot size max"
                                value={
                                  params?.maxLotSizeAcres
                                    ? {
                                      value: params?.maxLotSizeAcres,
                                      label: params?.maxLotSizeAcres,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxLotSizeAcres: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxLotSizeAcres: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* YEAR BUILT */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="year-built" className="form-label">
                            Year Built
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={yearBuiltMinOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Year Built"
                                aria-label="Year Built"
                                value={
                                  params?.minYearBuilt
                                    ? {
                                      value: params?.minYearBuilt,
                                      label: params?.minYearBuilt,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minYearBuilt: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minYearBuilt: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={yearBuiltMaxOptions}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Year Built"
                                aria-label="Year Built Max"
                                value={
                                  params?.maxYearBuilt
                                    ? {
                                      value: params?.maxYearBuilt,
                                      label: params?.maxYearBuilt,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxYearBuilt: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxYearBuilt: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* DAYS LISTED */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="days-listed" className="form-label">
                            Days Listed
                          </label>
                          <div className="row">
                            <div className="col-xl-12 col-sm-12">
                              <Select
                                options={timeOnAmberwood}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Time on Amberwood"
                                aria-label="Time on amberwood"
                                value={
                                  params?.maxDaysOnMarket
                                    ? {
                                      value: params?.maxDaysOnMarket,
                                      label: params?.maxDaysOnMarket,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxDaysOnMarket: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxDaysOnMarket: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* PROPERTY STATUS */}
                      <div className="col-xl-12 col-sm-12">
                        <h5 className="sub-heading">PROPERTY STATUS</h5>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                id="propertyStatus"
                                label="Active"
                                value="Active"
                                checked={params?.propertyStatus?.includes(
                                  "Active"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyStatus?.includes("Active")
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyStatus:
                                        params?.propertyStatus?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyStatus: [
                                        ...params?.propertyStatus,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Conditional"
                                id="Conditional"
                                value="Conditional"
                                checked={params?.propertyStatus?.includes(
                                  "Conditional"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyStatus?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyStatus:
                                        params?.propertyStatus?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyStatus: [
                                        ...params?.propertyStatus,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Pending"
                                id="Pending"
                                value="Pending"
                                checked={params?.propertyStatus?.includes(
                                  "Pending"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyStatus?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyStatus:
                                        params?.propertyStatus?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyStatus: [
                                        ...params?.propertyStatus,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Sold"
                                id="Sold"
                                value="Sold"
                                checked={params?.propertyStatus?.includes(
                                  "Sold"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyStatus?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyStatus:
                                        params?.propertyStatus?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyStatus: [
                                        ...params?.propertyStatus,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* PROPERTY TYPES */}
                      <div className="col-xl-12 col-sm-12">
                        <h5 className="sub-heading">PROPERTY TYPE</h5>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-6">
                              {/* Residential House */}
                              <Form.Check
                                className="status-checkbox"
                                label="House"
                                id="House"
                                value="Residential"
                                checked={params?.propertyType?.includes(
                                  "Residential"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyType:
                                        params?.propertyType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyType: [
                                        ...params?.propertyType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                              {/* Multi-Family */}
                              <Form.Check
                                className="status-checkbox"
                                label="Multi Family"
                                id="Multi Family"
                                value="MultiFamily"
                                checked={params?.propertyType?.includes(
                                  "MultiFamily"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyType:
                                        params?.propertyType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyType: [
                                        ...params?.propertyType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              {/* Condominium/Townhouse */}
                              <Form.Check
                                className="status-checkbox"
                                label="Condo"
                                id="Condo"
                                value="Condominium"
                                checked={params?.propertySubType?.includes(
                                  "Condominium"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertySubType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertySubType:
                                        params?.propertySubType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertySubType: [
                                        ...params?.propertySubType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                              {/* LAND */}
                              <Form.Check
                                className="status-checkbox"
                                label="Land"
                                id="Land"
                                value="Land"
                                checked={params?.propertyType?.includes("Land")}
                                onChange={(e) => {
                                  if (
                                    params?.propertyType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyType:
                                        params?.propertyType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyType: [
                                        ...params?.propertyType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              {/* Commerical */}
                              <Form.Check
                                className="status-checkbox"
                                label="Commercial"
                                id="Commercial"
                                value="Commercial"
                                checked={params?.propertyType?.includes(
                                  "Commercial"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyType:
                                        params?.propertyType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyType: [
                                        ...params?.propertyType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                              {/* Residential Lease */}
                              <Form.Check
                                className="status-checkbox"
                                label="Lease"
                                id="lease"
                                value="Rental"
                                checked={params?.propertyType?.includes(
                                  "Rental"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertyType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertyType:
                                        params?.propertyType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertyType: [
                                        ...params?.propertyType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              {/* StockCooperative */}
                              <Form.Check
                                className="status-checkbox"
                                label="Co-op"
                                id="Co-op"
                                value="StockCooperative"
                                checked={params?.propertySubType?.includes(
                                  "StockCooperative"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.propertySubType?.includes(
                                      e.target.value
                                    )
                                  ) {
                                    setParams({
                                      ...params,
                                      propertySubType:
                                        params?.propertySubType?.filter(
                                          (obj) => obj !== e.target.value
                                        ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      propertySubType: [
                                        ...params?.propertySubType,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                              {/* OTHERS */}
                              <Form.Check
                                className="status-checkbox"
                                label="Other Types"
                                id="Other Types"
                                value="others"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5 className="sub-heading">PROPERTY FEATURES</h5>

                      {/* PARKING SPACES */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="parking-spaces"
                            className="form-label"
                          >
                            Parking Spaces
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={parkingSpacesMin}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Parking Spaces"
                                aria-label="parking-spaces"
                                value={
                                  params?.minParkingSpace
                                    ? {
                                      value: params?.minParkingSpace,
                                      label: params?.minParkingSpace,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minParkingSpace: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minParkingSpace: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={parkingSpacesMax}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Parking Spaces"
                                aria-label="parking-spoaces-max"
                                value={
                                  params?.maxParkingSpace
                                    ? {
                                      value: params?.maxParkingSpace,
                                      label: params?.maxParkingSpace,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxParkingSpace: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxParkingSpace: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* STORIES */}
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="stories" className="form-label">
                            Stories
                          </label>
                          <div className="row">
                            <div className="col-xl-6 col-6">
                              <Select
                                options={storiesMin}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Min Stories"
                                aria-label="min-stories"
                                value={
                                  params?.minStories
                                    ? {
                                      value: params?.minStories,
                                      label: params?.minStories,
                                    }
                                    : { value: "No Min", label: "No Min" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Min") {
                                    setParams({
                                      ...params,
                                      minStories: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    minStories: e.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-xl-6 col-6">
                              <Select
                                options={storeisMax}
                                className="react-select"
                                classNamePrefix="custom-select"
                                placeholder="Max Stories"
                                aria-label="max-stories"
                                value={
                                  params?.maxStories
                                    ? {
                                      value: params?.maxStories,
                                      label: params?.maxStories,
                                    }
                                    : { value: "No Max", label: "No Max" }
                                }
                                onChange={(e) => {
                                  if (e.value === "No Max") {
                                    setParams({
                                      ...params,
                                      maxStories: null,
                                    });
                                    return;
                                  }
                                  setParams({
                                    ...params,
                                    maxStories: e.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* PROPERTY FEATURES */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Pool"
                                id="propertyFeatures-1"
                                value="Pool"
                                checked={params?.appliances?.includes("Pool")}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Washer/Dryer"
                                id="propertyFeatures-2"
                                value="WasherDryer"
                                checked={params?.appliances?.includes(
                                  "WasherDryer"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Doorman"
                                id="propertyFeatures-3"
                                value="DoorMan"
                                checked={params?.appliances?.includes(
                                  "DoorMan"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Elevator"
                                id="propertyFeatures-4"
                                value="Elevators"
                                checked={params?.appliances?.includes(
                                  "Elevators"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Garage"
                                id="propertyFeatures-5"
                                value="GarageSpaces"
                                checked={params?.appliances?.includes(
                                  "GarageSpaces"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Waterfront"
                                id="propertyFeatures-6"
                                value="WaterFront"
                                checked={params?.appliances?.includes(
                                  "WaterFront"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3 col-6">
                              <Form.Check
                                className="status-checkbox"
                                label="Air Conditioning"
                                id="propertyFeatures-7"
                                value="AirCondition"
                                checked={params?.appliances?.includes(
                                  "AirCondition"
                                )}
                                onChange={(e) => {
                                  if (
                                    params?.appliances?.includes(e.target.value)
                                  ) {
                                    setParams({
                                      ...params,
                                      appliances: params?.appliances?.filter(
                                        (obj) => obj !== e.target.value
                                      ),
                                    });
                                  } else {
                                    setParams({
                                      ...params,
                                      appliances: [
                                        ...params?.appliances,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* FOOTER */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="filters-footer">
                          <Button
                            className="filters-btn"
                            onClick={clearFilters}
                          >
                            <p>Reset Filters</p>
                          </Button>
                          <Button
                            className="apply-filters-btn"
                            aria-label="Apply Filters"
                            onClick={handleApplyFiltera}
                          >
                            Apply Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </motion.div>

                <div className="section-body">
                  <AnimatePresence>
                    {loading ? (
                      <>
                        {/* LOADING SKELETON */}
                        <div className="row gy-4">
                          <div className="col-xl-12 col-sm-12">
                            <PropertyLoadingList />
                          </div>
                          <div className="col-xl-12 col-sm-12">
                            <PropertyLoadingList />
                          </div>
                          <div className="col-xl-12 col-sm-12">
                            <PropertyLoadingList />
                          </div>
                          <div className="col-xl-12 col-sm-12">
                            <PropertyLoadingList />
                          </div>
                        </div>
                      </>
                    ) : (
                      false
                    )}

                    <div className={`row ${mlsTotal > 0 ? "gy-4" : ""}`}>
                      {mlsTotal > 0 &&
                        mlsData?.map((property, id) => {
                          var finalDOM = 0;
                          const todayDate = new Date();
                          const replicationDate = new Date(
                            property.DaysOnMarketReplicationDate
                          );
                          const timeDiff = Math.abs(
                            todayDate - replicationDate
                          );
                          const diffDays = Math.ceil(
                            timeDiff / (1000 * 60 * 60 * 24)
                          );

                          if (property.DaysOnMarketReplicationIncreasingYN) {
                            finalDOM =
                              property.DaysOnMarketReplication + diffDays;
                          } else {
                            finalDOM = property.DaysOnMarketReplication;
                          }
                          return (
                            <div className="col-xl-12 col-sm-12" key={id}>
                              <ListingListView
                                imgList={property?.Media}
                                streetName={property.UnparsedAddress}
                                cityName={property.City}
                                price={property.ListPrice}
                                beds={property.BedroomsTotal}
                                baths={property.BathroomsTotalInteger}
                                remainingDays={finalDOM}
                                data={property}
                                area={property.LivingArea}
                                mlsNumber={property.ListingKey}
                                leaseIndicator={property.SaleOrLeaseIndicator}
                                /* lot={property.LotSizeAcres} */
                                mlsKey={property.OriginatingSystemName}
                              />
                            </div>
                          );
                        })}
                    </div>

                    {/* NO PROPERTIES FOUND */}
                    <div
                      className={`no-properties-found-box ${mlsTotal === 0 ? "d-block" : "d-none"
                        }`}
                    >
                      <h4>No properties found</h4>
                      <p>Try a different search or change your filters</p>
                    </div>

                    {/* PAGINATION  */}
                    <ReactPaginate
                      forcePage={page - 1}
                      className={`blogs-pagination mt-3 ${mlsTotal === 0 ? "d-none" : "d-flex"
                        }`}
                      previousLabel={
                        <Button
                          className="blog-pagination-btn"
                          aria-label="Previous Pagination"
                        >
                          <PreviousIcon color="#323232" size="20" />
                        </Button>
                      }
                      nextLabel={
                        <Button
                          className="blog-pagination-btn"
                          aria-label="Next Pagination"
                        >
                          <NextIcon color="#323232" size="20" />
                        </Button>
                      }
                      breakLabel={"..."}
                      pageCount={totalPages}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      activeClassName={"active"}
                    />
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>

            {/* MAP SECTION */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-6 col-sm-12"
            >
              <div className="listings">
                {/* GOOGLE MAP WITH MARKERS */}
                <GoogleMap
                  id="map"
                  mapContainerStyle={mapContainerStyle}
                  zoom={zoom}
                  center={center}
                  options={options}
                  onLoad={onMapLoad}
                  onDragEnd={() => {
                    var bb = mapRef.current.getBounds();
                    var ne = bb.getNorthEast(); // top-left
                    var sw = bb.getSouthWest(); // bottom-right

                    if (sw.lat() && ne.lat() && sw.lng() && ne.lng()) {
                      setCallOutFunction(true);
                      setParams({
                        ...params,
                        minLatitude: sw?.lat(),
                        maxLatitude: ne?.lat(),
                        minLongitude: sw?.lng(),
                        maxLongitude: ne?.lng(),
                        Latitude: null,
                        Longitude: null,
                        city: null,
                        state: null,
                        country: null,
                        useLatLng: true,
                        isFilter: true,
                        page: 1,
                      });
                    }
                  }}
                  onZoomChanged={() => {
                    var bb = mapRef?.current?.getBounds();
                    var ne = bb?.getNorthEast(); // top-left
                    var sw = bb?.getSouthWest(); // bottom-right
                    if (sw?.lng() && ne?.lng() && sw?.lat() && ne?.lat()) {
                      setCallOutFunction(true);
                      setParams({
                        ...params,
                        minLatitude: sw?.lat(),
                        maxLatitude: ne?.lat(),
                        minLongitude: sw?.lng(),
                        maxLongitude: ne?.lng(),
                        Latitude: null,
                        Longitude: null,
                        city: null,
                        state: null,
                        country: null,
                        useLatLng: true,
                        isFilter: true,
                        page: 1,
                      });
                    }
                  }}
                >
                  {markers.map((marker, id) => (
                    <OverlayView
                      key={`${marker.lat}-${marker.lng}`}
                      mapPaneName="floatPane"
                      position={{ lat: marker.lat, lng: marker.lng }}
                    >
                      <MapMarker
                        onclickFn={() => setSelected(marker)}
                        imageSelected={marker?.data.Media?.[0]?.MediaURL}
                      />
                    </OverlayView>
                  ))}

                  {selected ? (
                    <InfoWindow
                      position={{
                        lat: selected.lat + 0.0015,
                        lng: selected.lng,
                      }}
                      onCloseClick={() => {
                        setSelected(null);
                      }}
                      zIndex={100}
                    >
                      <Link
                        to={`/property-detail/${selected?.data?.ListingKey}/${selected?.data?.OriginatingSystemName}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="popover-property-details">
                          <div className="property-img">
                            <img
                              src={selected?.data?.Media?.[0]?.MediaURL}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = AmberwoodPlaceholder;
                              }}
                              alt="Property"
                            />
                          </div>
                          <div className="property-details">
                            <h5>{selected?.data?.UnparsedAddress}</h5>
                            <div className="other-details">
                              <p>${selected?.data.ListPrice}</p>
                              <div className="bed-bath-details">
                                <p className="bedbath">
                                  {selected?.data.BedroomsTotal} BD
                                </p>
                                <p className="bedbath">
                                  {selected?.data.BathroomsTotalInteger} BA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </InfoWindow>
                  ) : null}
                </GoogleMap>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
/* SEARCH FUNCTION */
function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });

      const { lat, lng } = await getLatLng(results[0]);

      const AddressComponent = results?.[0]?.address_components;

      let country = await AddressComponent?.find(
        (type) => type?.types?.[0] === "country"
      ).short_name;
      let state = null;
      let city = null;
      let unparsedAddress = null;

      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "street_number"
        ) !== undefined &&
        AddressComponent?.find((type) => type?.types?.[0] === "route") !==
        undefined
      ) {
        unparsedAddress =
          AddressComponent?.find((type) => type?.types?.[0] === "street_number")
            .long_name +
          " " +
          AddressComponent?.find((type) => type?.types?.[0] === "route")
            .long_name;
      }
      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        )
      ) {
        state = await AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        ).short_name;
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "locality")) {
        city = await AddressComponent?.find(
          (type) => type?.types?.[0] === "locality"
        ).long_name;
      }

      panTo({ lat, lng, city, state, country, unparsedAddress });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="search p-relative">
      <div className="form-group p-relative mb-0 ">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            id="address-input"
            onChange={handleInput}
            disabled={!ready}
            className="form-control"
            placeholder="Search your city or neighborhood"
          />
          <ComboboxPopover className="combobbox-popover-styles">
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        <Button
          className="locate-reset-btn"
          onClick={() => {
            panTo({
              lat: 33.87729927050145,
              lng: -118.05763493340501,
            });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}
export default FeaturedListings;
