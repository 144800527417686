import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

/* LAYOUT IMPORTS */
import Layout from "../layout/index";

/* PAGES IMPORT */
import Home from "../pages/index";
import BuyerOverview from "../pages/buying";
import SellerOverview from "../pages/selling";
import ContactUs from "../pages/contact-me";
import Blogs from "../pages/blogs";
import BlogDetail from "../pages/blogs/blog-detail";
import ActiveListings from "../pages/active-listings";
import PropertyDetails from "../pages/active-listings/detail-view";
import FeaturedListings from "../pages/active-listings/featured-listing";
import HomeEvaluation from "../pages/home-evaluation";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebsiteRoutes = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <div className="app">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/buying"
              element={
                <Layout>
                  <BuyerOverview />
                </Layout>
              }
            />
            <Route
              path="/selling"
              element={
                <Layout>
                  <SellerOverview />
                </Layout>
              }
            />
            <Route
              path="/contact-me"
              element={
                <Layout>
                  <ContactUs />
                </Layout>
              }
            />
            <Route
              path="/blogs"
              element={
                <Layout>
                  <Blogs />
                </Layout>
              }
            />
            <Route
              path="/blog-detail/:id"
              element={
                <Layout>
                  <BlogDetail />
                </Layout>
              }
            />
            <Route
              path="/active-listings"
              element={
                <Layout>
                  <ActiveListings />
                </Layout>
              }
            />
            <Route
              path="/property-detail/:mlsId/:mlsKey"
              element={
                <Layout>
                  <PropertyDetails />
                </Layout>
              }
            />
            <Route
              path="/featured-listings"
              element={
                <Layout>
                  <FeaturedListings />
                </Layout>
              }
            />
            <Route
              path="/home-evaluation"
              element={
                <Layout>
                  <HomeEvaluation />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default WebsiteRoutes;
