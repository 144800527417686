/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import actions from "../redux/actions/userAction";
import { motion } from "framer-motion";

/* IMAGE */
import Placeholder from "../assets/featured-home-placeholder.jpg";

/* APIS */
import { getMLS } from "../service/api";

/* ICON IMPORTS */
import { IoBedOutline } from "react-icons/io5";
import { BiBath } from "react-icons/bi";

const PropertyBoxLoader = () => {
  return (
    <Card className="property-box">
      <Card.Body className="property-box-body">
        <img
          src={Placeholder}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = Placeholder;
          }}
          className="image-loading-property"
          alt="Property Box"
        />
        <div className="details">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <div className="more-details">
            <div className="type">
              <IoBedOutline color="#323232" size="20" />
              <Skeleton className="bed-loading" />
            </div>
            <div className="type">
              <BiBath color="#323232" size="20" />
              <Skeleton className="bed-loading" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const PropertyBox = (props) => {
  return (
    <Link
      to={`/property-detail/${props?.mlsNumber}/${props?.mlsKey}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Card className="property-box">
        <Card.Body className="property-box-body">
          {props?.image === null ? (
            <img
              src={Placeholder}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = Placeholder;
              }}
              alt={props.streetName}
            />
          ) : (
            props?.image
              ?.filter((img) =>
                img?.MediaURL?.includes(
                  "https://api-trestle.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-Jpeg/" +
                    props?.mlsNumber +
                    "/1/"
                )
              )
              .map((img) => {
                return (
                  <img
                    src={img?.MediaURL}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = Placeholder;
                    }}
                    alt={props.streetName}
                  />
                );
              })
          )}
          <div className="details">
            <h5 className="price">${props.price}</h5>
            <h5>{props.streetName}</h5>
            <p>{props.cityName}</p>
            <div className="more-details">
              <div className="type">
                <IoBedOutline color="#323232" size="20" />
                <p>
                  {props?.bedrooms === null ? "0" : props?.bedrooms} Bedrooms
                </p>
              </div>
              <div className="type">
                <BiBath color="#323232" size="20" />
                <p>
                  {props?.bathrooms === null ? "0" : props?.bathrooms} Bathrooms
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};

const FeaturedListings = () => {
  const [page, setPage] = useState(1);
  const [MLSLoading, setMLSLoading] = useState(false);
  const [data, setData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  /* DEFAULT FILTERS */
  let defaultFilters = {
    page: page,
    isFilter: true,
    propertyStatus: ["Active"],
    minBedroom: null,
    maxBedroom: null,
    minPrice: 1,
    maxPrice: null,
    minBath: null,
    maxBath: null,
    location: null,
    featured: true,
    propertySubType: [],
    propertyType: [],
    minYearBuilt: null,
    maxYearBuilt: null,
    minDaysOnMarket: null,
    maxDaysOnMarket: null,
    minLotSize: null,
    maxLotSize: null,
    minLotSizeAcres: null,
    maxLotSizeAcres: null,
    minLatitude: null,
    maxLatitude: null,
    minLongitude: null,
    maxLongitude: null,
    sortBy: null,
  };

  const [params, setParams] = useState(defaultFilters);

  /* GET MLS DATA */
  const getMLSData = () => {
    setMLSLoading(true);

    let datas = params;
    let obj = datas;
    obj.page = page;
    obj.featured = true;

    getMLS(obj)
      .then((res) => {
        if (res.status) {
          let list = [];
          let length = 0;
          res?.data?.map((obj) => {
            if (obj?.value?.length > 0) {
              list = list.concat(obj?.value);
              length += obj["@odata.count"];
            }
          });
          setData(list.reverse());
          if (length > 0) {
            list?.forEach((element) => {
              setMarkers((current) => [
                ...current,
                {
                  lat: element?.Latitude,
                  lng: element?.Longitude,
                  data: element,
                  time: new Date(),
                },
              ]);
            });
          }
          setTotal(length);
          setTotalPages(length / 20);
          dispatch(actions.setMLS(res?.data[1]?.value));
        }
      })
      .finally((res) => {
        setMLSLoading(false);
      });
  };

  /* USEEFFECT */
  useEffect(() => {
    getMLSData();
  }, [page]);

  console.log("DATA: ", data);

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="featured-listings"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <div className="heading">
              <h2>EXPLORE THE FEATURED PROPERTIES</h2>
              <Link to="/featured-listings" className="listings-link">
                View all listings
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <Swiper
            spaceBetween={30}
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 4,
              },
            }}
            modules={[Scrollbar, Navigation, Pagination]}
            navigation={true}
            className="featured-swiper"
          >
            {MLSLoading ? (
              <>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="col-xl-12 col-sm-12">
                    <PropertyBoxLoader />
                  </div>
                </SwiperSlide>
              </>
            ) : (
              false
            )}

            {total > 0 &&
              data?.map((property) => {
                return (
                  <SwiperSlide>
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                      <PropertyBox
                        image={property?.Media}
                        streetName={property?.UnparsedAddress}
                        cityName={property.City}
                        price={property?.ListPrice}
                        bedrooms={property?.BedroomsTotal}
                        bathrooms={property?.BathroomsTotalInteger}
                        mlsNumber={property?.ListingKey}
                        mlsKey={property.OriginatingSystemName}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </motion.section>
  );
};

export default FeaturedListings;
