/* ICON IMPORTS */
import SellHome from "../assets/icons/sell-your-home.png";
import BuyHome from "../assets/icons/buy-home.png";
import LegalServices from "../assets/icons/legal-services.png";
import PreQualifiedLoan from "../assets/icons/pre-qualified-loan.png";
import HomeInsurance from "../assets/icons/home-insurance.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
 
/* REDUX */
import actions from "../redux/actions/userAction";

import { useDispatch } from "react-redux";

const ServiceBox = (props) => {
  const dispatch = useDispatch();
  return (
    <Link onClick={()=>{dispatch(actions.setContactUsHeader(props?.headerTitle))}} to={props.navLink} style={{ textDecoration: 'none' }}>
      <div className="services-box">
        <img src={props.icon} alt={props.title} />
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </Link>
  );
};

const Services = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="services"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-sm-12">
            <div className="heading">
              <h2>
                EXCLUSIVE. COMMITTED. PROFESSIONAL SERVICES WE CAN OFFER YOU
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gy-4 mt-4">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <ServiceBox
              icon={SellHome}
              title="Sell your Home"
              description="Effortlessly sell your home with expert guidance and seamless process"
              navLink="/selling"
              headerTitle=""
            />
          </div>
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <ServiceBox
              icon={BuyHome}
              title="Buy a Home"
              description="Discover your dream home with the expert guidance throughout the buying process"
              navLink="/buying"
              headerTitle=""
            />
          </div>
        </div>
        <div className="row justify-content-center gy-4 mt-2">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <ServiceBox
              icon={PreQualifiedLoan}
              title="Get Pre Qualified"
              description="Get pre-qualified for a loan and take the first step towards homeownership today!"
              navLink="/contact-me"
              headerTitle="Unlock your real estate dreams with a pre-qualified loan. Take the first step towards homeownership today and get pre-qualified with our experienced lending team"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <ServiceBox
              icon={LegalServices}
              title="Legal Services"
              description="Receive support tailored to your specific needs with comprehensive legal services."
              navLink="/contact-me"
              headerTitle="Navigate the complexities of real estate with confidence. Reach out to the me and gain a competitive edge!"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <ServiceBox
              icon={HomeInsurance}
              title="Home Insurance"
              description="Protect your most valuable asset with reliable home insurance coverage"
              navLink="/contact-me"
              headerTitle="Safeguard your home and peace of mind. Get the best home insurance coverage now and ensure your property is protected for the future"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Services;
