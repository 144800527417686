import { motion } from "framer-motion";

const TestimonialBox = (props) => {
  return (
    <div className="testimonial-box">
      <h5>{props.title}</h5>
      <p>{props.description}</p>
      <div className="testimonial-profile">
        <img src={props.userProfile} alt={props.name} />
        <div className="profile-details">
          <h5>{props.name}</h5>
          <p>{props.userRole}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="testimonials"
    >
      <div className="container">
        <div className="heading">
          <h2>WHAT CLIENTS SAY ABOUT ME</h2>
          <h5>
            Read what our satisfied clients have to say about their experience
            working with me.
          </h5>
        </div>
        <div className="row mt-2 gy-4 justify-content-center">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Best Selling Experience"
              description="Benny is knowledgeable and was always available. Calls were returned quickly whenever I had a question or concern. He knew our area and provided valuable advice in negotiating our sale."
              name="Cherith Nelson"
              userProfile="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Great Experience"
              description="Thanks to Benny, I was able to relocate my family withing a short 30 days from my first offer. Benny, You Rock and look forward for your down to earth expertize help in the near future."
              name="Derek McDaniel"
              userProfile="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1148&q=80"
              userRole="Real Estate Buyer"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Easy Buying Experience"
              description="Benny is an honest person I'm glad we chose him to help us find our first home.  My husband and I will be recommending Benny Chavez to our friends and family. Benny was always very helpful answered all our questions."
              name="Erika Casillas"
              userProfile="https://images.unsplash.com/photo-1619895862022-09114b41f16f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Buyer"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Selling with Ease"
              description="Benny was truly a pleasure work with and an amazing guy. He did everything he could to help us sell our first home. The process was enjoyable and we felt like we were in good hands at all times. Plan to use him again in the future when we sell again. :)"
              name="Vijay Suzuk"
              userProfile="https://images.unsplash.com/photo-1530268729831-4b0b9e170218?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Amazin to work with"
              description="Benny is an amazing agent all around!!! Everything you could ask for in an agent. He genuinely takes pride in assisting your needs like you were his personal family. He helped us out of a bad situation with our home and looked out for us the entire way"
              name="Michelle Gonzales"
              userProfile="https://images.unsplash.com/photo-1533689476487-034f57831a58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80"
              userRole="Real Estate Seller"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonials;
