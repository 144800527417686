/* ICONS */
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-main">
          <div className="col-xl-12 col-sm-12">
            <h4 className="agent-name">AMBERWOOD REAL ESTATE INC.</h4>
          </div>
          <div className="col-xl-12 col-sm-12">
            <div className="agent-details">
              <div className="details-box">
                <h5>BENNY CHAVEZ</h5>
                <p>DRE #01805872</p>
              </div>
              <div className="details-box">
                <h5>EMAIL ADDRESS</h5>
                <a href="mailto:benny@lahomeguy.com">benny@lahomeguy.com</a>
              </div>
              <div className="details-box">
                <h5>PHONE</h5>
                <a href="tel:+15628967979">(562) 896-7979</a>
              </div>
              <div className="details-box">
                <h5>ADDRESS</h5>
                <a
                  href="https://goo.gl/maps/dGB7bR1pBhzRCYjX6"
                  target="_blank"
                  rel="noreferrer"
                >
                  11060 Artesia Blvd Suite G, Cerritos, CA 90703
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-sm-12">
            <p className="footer-small-info">
              Benny Chavez is a real estate agent licensed by the state of
              California affiliated with Amberwood Real Estate. Benny Chavez is
              a real estate agent licensed by the state of California and
              abides by equal housing opportunity laws. All material presented
              herein is intended for informational purposes only. Information is
              compiled from sources deemed reliable but is subject to errors,
              omissions, changes in price, condition, sale, or withdrawal
              without notice. No statement is made as to accuracy of any
              description. All measurements and square footages are approximate.
              This is not intended to solicit property already listed. Nothing
              herein shall be construed as legal, accounting or other
              professional advice outside the realm of real estate brokerage.
            </p>
          </div>
        </div>
        <div className="copyright-main">
          <h5>
            © 2024, BENNY CHAVEZ. POWERED BY{" "}
            <a
              href="https://www.roundtechsquare.com"
              target="_blank"
              rel="noreferrer"
            >
              ROUNDTECHSQUARE
            </a>
          </h5>
          <div className="links">
            <a
              href="https://www.facebook.com/LAhomeguy"
              target="_blank"
              rel="noreferrer"
              className="social-media-box"
            >
              <BsFacebook color="#323232" size="16" />
            </a>
            <a
              href="https://instagram.com/lahomeguy"
              target="_blank"
              rel="noreferrer"
              className="social-media-box"
            >
              <AiFillInstagram color="#323232" size="16" />
            </a>
            <a
              href="https://www.linkedin.com/in/bennychavez/"
              target="_blank"
              rel="noreferrer"
              className="social-media-box"
            >
              <BsLinkedin color="#323232" size="16" />
            </a>
            <a
              href="https://twitter.com/LAhomeGuy"
              target="_blank"
              rel="noreferrer"
              className="social-media-box"
            >
              <BsTwitter color="#323232" size="16" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
